import React from "react";
import "./ourSegmentsNew.css";
import AgriImage from "../../images/agriProduceHome.webp";
import CropImage from "../../images/cropSolutionHome.webp";
import LivestockImage from "../../images/liveStockSolutionHome.webp";
import HealthImage from "../../images/healthCareHome.webp";
import HealthImage1 from "../../images/healthCareHome1.webp";
import IndustrialImage from "../../images/industrialSolutionHome.webp";
import IndustrialImage1 from "../../images/industrialSolutionHome1.webp";
import Vector5Arrow from "../../images/Vector5Arrow.png";

const OurSegmentsNew = () => {
  return (
    <div className="homeOurSegment-layout">
      <h1
        className="homeOurSegment-title"
        data-aos="fade-right"
        data-aos-duration="700"
      >
        Our Segments
      </h1>
      <div className="homeOurSegment-info">
        <p
          className="homeOurSegment-description"
          data-aos="fade-right"
          data-aos-duration="700"
          data-aos-delay="100"
        >
          CIC Holdings PLC has clustered its business ventures into five
          distinct segments.
        </p>
        <div>
          <a href="/our-segments/" className="homeOurSegment-link">
            <button
              className="homeOurSegment-readMore hover-effect"
              data-aos="fade-left"
              data-aos-duration="700"
            >
              Read More
              <span className="homeOurSegment-arrow hover-effect"></span>
            </button>
          </a>
        </div>
      </div>
      <div
        className="homeOurSegment-grid"
        data-aos="zoom-in"
        data-aos-duration="750"
      >
        <div className="homeOurSegment-card">
          <img
            src={AgriImage}
            alt="Agri Produce"
            className="homeOurSegment-image"
          />
          <div className="homeOurSegment-overlay">
            <span className="homeOurSegment-text">Agri Produce</span>
            <a href="/agri-produce/" className="homeOurSegment-icon">
              <img
                className="vector5arrowSeg"
                alt="loading"
                src={Vector5Arrow}
              />
            </a>
          </div>
        </div>
        <div className="homeOurSegment-card">
          <img
            src={CropImage}
            alt="Crop Solutions"
            className="homeOurSegment-image"
          />
          <div className="homeOurSegment-overlay">
            <span className="homeOurSegment-text">Crop Solutions</span>
            <a href="/crop-solutions/" className="homeOurSegment-icon">
              <img
                className="vector5arrowSeg"
                alt="loading"
                src={Vector5Arrow}
              />
            </a>
          </div>
        </div>
        <div className="homeOurSegment-card">
          <img
            src={LivestockImage}
            alt="Livestock Solutions"
            className="homeOurSegment-image"
          />
          <div className="homeOurSegment-overlay">
            <span className="homeOurSegment-text margin-fix">
              Livestock Solutions
            </span>
            <a href="/livestock-solutions/" className="homeOurSegment-icon">
              <img
                className="vector5arrowSeg"
                alt="loading"
                src={Vector5Arrow}
              />
            </a>
          </div>
        </div>
        <div className="homeOurSegment-card">
          <img
            src={HealthImage}
            alt="Health & Personal Care"
            className="homeOurSegment-image hide-on-small-screens"
          />
          <img
            src={HealthImage1}
            alt="Health & Personal Care"
            className="homeOurSegment-image show-on-small-screens"
          />
          <div className="homeOurSegment-overlay">
            <span className="homeOurSegment-text margin-fix1">
              Health & Personal Care
            </span>
            <a href="/health-care/" className="homeOurSegment-icon">
              <img
                className="vector5arrowSeg"
                alt="loading"
                src={Vector5Arrow}
              />
            </a>
          </div>
        </div>

        <div className="homeOurSegment-card">
          <img
            src={IndustrialImage}
            alt="Industrial Solutions"
            className="homeOurSegment-image hide-on-small-screens"
          />
          <img
            src={IndustrialImage1}
            alt="Industrial Solutions"
            className="homeOurSegment-image show-on-small-screens"
          />
          <div className="homeOurSegment-overlay">
            <span className="homeOurSegment-text margin-fix3">
              Industrial Solutions
            </span>
            <a href="/industrial-solutions/" className="homeOurSegment-icon">
              <img
                className="vector5arrowSeg"
                alt="loading"
                src={Vector5Arrow}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurSegmentsNew;
