import React, { useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import "./aboutCIC.css";
// import Vector6Arrow from "../../images/Vector6Arrow.png";
import Image3 from "../../images/aboutcic3.webp";

export default function AboutCIC() {
  const incrementRef = useRef(null);

  useEffect(() => {
    const incrementNumber = () => {
      let start = 0;
      const end = 7.69;
      const duration = 2000;
      const increment = end / (duration / 16);

      const animate = () => {
        start += increment;
        if (incrementRef.current) {
          // Check if the ref exists
          if (start < end) {
            incrementRef.current.textContent = `Rs. ${start.toFixed(2)} Bn`;
            requestAnimationFrame(animate);
          } else {
            incrementRef.current.textContent = `Rs. ${end.toFixed(2)} Bn`;
          }
        }
      };

      requestAnimationFrame(animate);
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && incrementRef.current) {
          // Check if ref exists and is intersecting
          incrementNumber();
          observer.unobserve(incrementRef.current); // Stop observing once the animation starts
        }
      });
    });

    if (incrementRef.current) {
      observer.observe(incrementRef.current); // Only observe if the ref is not null
    }

    return () => {
      if (observer && incrementRef.current) {
        observer.unobserve(incrementRef.current); // Cleanup the observer when component unmounts
      }
    };
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const wpdata = useStaticQuery(graphql`
    query {
      allWpPage(filter: { slug: { eq: "home" } }) {
        nodes {
          homePageAboutUsPage {
            aboutUsDescription {
              title24
              paragraph14
            }
            subSector21 {
              title25
              description3
              image17 {
                node {
                  mediaItemUrl
                }
              }
            }
            subSector22 {
              title26
              image18 {
                node {
                  mediaItemUrl
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div className="s2-home" data-aos="zoom-in" data-aos-duration="1000">
      <center>
        <div className="page-container">
          {/* <div className="column" data-aos="fade-left" data-aos-duration="1000"> */}
          <div className="column">
            {wpdata.allWpPage.nodes.map((page, index) => (
              <div key={index}>
                <h2
                  className="aboutcic-h1"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  {page.homePageAboutUsPage.aboutUsDescription.title24}
                </h2>

                {/* <div className="video-wrap">
                    <h1 className="video-wrap-h1">MEET THE TEAM </h1>
                </div> */}

                <p
                  className="aboutcic-p1"
                  dangerouslySetInnerHTML={{
                    __html:
                      page.homePageAboutUsPage.aboutUsDescription.paragraph14,
                  }}
                />
              </div>
            ))}
            <div>
              <a
                href="/about-us/"
                className="homeAboutCIC-link"
                data-aos="zoom-in"
                data-aos-duration="700"
              >
                <button className="homeAboutCIC-readMore hover-effect">
                  Read More
                  <span className="homeAboutCIC-arrow hover-effect"></span>
                </button>
              </a>
            </div>
          </div>
          {/* <div className="column" data-aos="fade-left" data-aos-duration="1000"> */}
          <div className="column">
            <div className="image-container">
              {wpdata.allWpPage.nodes.map((page, index) => (
                <div key={index}>
                  <img
                    className="image2"
                    alt="loading"
                    src={
                      page.homePageAboutUsPage.subSector21.image17.node
                        .mediaItemUrl
                    }
                  />
                  <div className="s2-image-p">
                    {/* <h2 className="aboutcic-h2">{page.homePageAboutUsPage.subSector21.title25}</h2> */}
                    <h2 ref={incrementRef} className="aboutcic-h2">
                      Rs. 0.00 Bn
                    </h2>
                    <p className="aboutcic-p2">
                      {page.homePageAboutUsPage.subSector21.description3}
                    </p>
                  </div>
                </div>
              ))}
              <a
                href="/investor-desk/"
                className="homeAboutCIC-link"
                data-aos="zoom-in"
                data-aos-duration="700"
              >
                <button className="home-target_button1 hover-effect">
                  Read More
                  <span className="home-target_button1-arrow hover-effect"></span>
                </button>
              </a>
            </div>
          </div>
          {/* <div className="column" data-aos="fade-left" data-aos-duration="1000"> */}
          <div className="column">
            <div className="image-container">
              {wpdata.allWpPage.nodes.map((page, index) => (
                <div key={index}>
                  {/* <img className="image2" alt="loading" src={page.homePageAboutUsPage.subSector22.image18.node.mediaItemUrl} /> */}
                  <img className="image2" alt="" src={Image3} />
                  <div className="s2-image-p">
                    <p className="aboutcic-p3">
                      {page.homePageAboutUsPage.subSector22.title26}
                    </p>
                  </div>
                  <a
                    href="/sustainability/"
                    className="homeAboutCIC-link"
                    data-aos="zoom-in"
                    data-aos-duration="700"
                  >
                    <button className="home-target_button2 hover-effect">
                      Read More
                      <span className="home-target_button2-arrow hover-effect"></span>
                    </button>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </center>
    </div>
  );
}

// import React from "react"
// import "./aboutCIC.css"
// import Image1 from "../../images/2.jpg"
// import Image2 from "../../images/aboutcic2.png"

// export default function AboutCIC() {
//   return (
//     <div className="s2-home">
//       <center>
//         <div className="page-container">
//           <div className="column">
//             <h2 className="aboutcic-h1">About CIC</h2>
//             <p className="aboutcic-p1">CIC Holdings PLC is one of Sri Lanka’s leading conglomerates with ventures in
//               businesses ranging from agriculture to chemicals and from medicine to household products.
//               Guided by strong ethics and a culture of growth, CIC has become a household name with which every Sri Lankan
//               is familiar.
//               CIC’s strength locally has enabled the company to reach a growing international market.</p>
//           </div>
//           <div className="column">
//             <div className="image-container">
//               <img className="image2" alt="" src={Image1} />
//               <div className="s2-image-p">
//                 <h2 className="aboutcic-h2">Rs. 7.69 Bn</h2>
//                 <br />
//                 <p className="aboutcic-p2">Profit in Q3 <br /> 2023/24</p>
//               </div>
//             </div>
//           </div>
//           <div className="column">
//             <div className="image-container">
//               <img className="image2" alt="" src={Image2} />
//               <div className="s2-image-p">
//                 <p className="aboutcic-p3">Heading towards <br /> sustainable growth</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </center>
//     </div>
//   )
// }
