import React, { useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import "./growth.css";
import Aos from "aos";
import "aos/dist/aos.css";
// import video from "../../images/growthVideo.png";
// import GrowthVideo from "../../images/growthVideo1.mp4";
import Economy from "../../images/growth-economic.png";
import Social from "../../images/growth-public-service.png";
import Environmental from "../../images/growth-ecology.png";
import Vector6Arrow from "../../images/Vector6Arrow.png";

const Growth = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const wpdata = useStaticQuery(graphql`
    query {
      allWpPage(filter: { slug: { eq: "home" } }) {
        nodes {
          homePageSustainability {
            content8 {
              title19
              title20
              paragraph10
            }
            subSector18 {
              title21
              paragraph11
            }
            subSector19 {
              title22
              paragraph12
            }
            subSector20 {
              title23
              paragraph13
            }
          }
        }
      }
    }
  `);

  return (
    <div>
      <div className="growth-first-container">
        <div className="growth-first-video">
          <video
            src="https://cic-2024.sgp1.digitaloceanspaces.com/videos/growthVideo.mp4"
            alt="loading"
            loop
            controls
          />
        </div>
        <div className="growth-first-content">
          {wpdata.allWpPage.nodes.map((page, index) => (
            <div key={index}>
              <h2
                className="growth-brand-h2"
                data-aos="fade-left"
                data-aos-duration="500"
              >
                {page.homePageSustainability.content8.title19}
              </h2>
              <h2
                className="growth-sustain"
                data-aos="fade-left"
                data-aos-duration="600"
              >
                {page.homePageSustainability.content8.title20}
              </h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: page.homePageSustainability.content8.paragraph10,
                }}
                data-aos="fade-left"
                data-aos-duration="700"
                className="growth-brand-p"
              />
              <div
                className="growth-target_button"
                data-aos="fade-left"
                data-aos-duration="700"
              >
                Read more
                <img
                  className="vector6arrow"
                  alt="loading"
                  src={Vector6Arrow}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* 3 column */}
      <center>
        <div
          className="bottomGrowth-page-container"
          data-aos="zoom-in"
          data-aos-duration="600"
        >
          <div className="bottomGrowth-row">
            <div className="bottomGrowth-column">
              <img
                className="bottomGrowth-image2"
                alt="loading"
                src={Economy}
              />
            </div>
            <div className="bottomGrowth-column">
              <div className="bottomGrowth-box">
                {wpdata.allWpPage.nodes.map((page, index) => (
                  <div key={index}>
                    <h2 className="bottomGrowth-h1">
                      {page.homePageSustainability.subSector18.title21}
                    </h2>
                    <p
                      className="bottomGrowth-p1"
                      dangerouslySetInnerHTML={{
                        __html:
                          page.homePageSustainability.subSector18.paragraph11,
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="bottomGrowth-row">
            <div className="bottomGrowth-column">
              <img className="bottomGrowth-image2" alt="" src={Social} />
            </div>
            <div className="bottomGrowth-column">
              <div className="bottomGrowth-box">
                {wpdata.allWpPage.nodes.map((page, index) => (
                  <div key={index}>
                    <h2 className="bottomGrowth-h1">
                      {page.homePageSustainability.subSector19.title22}
                    </h2>
                    <p
                      className="bottomGrowth-p1"
                      dangerouslySetInnerHTML={{
                        __html:
                          page.homePageSustainability.subSector19.paragraph12,
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="bottomGrowth-row">
            <div className="bottomGrowth-column">
              <img className="bottomGrowth-image2" alt="" src={Environmental} />
            </div>
            <div className="bottomGrowth-column">
              <div className="bottomGrowth-box">
                {wpdata.allWpPage.nodes.map((page, index) => (
                  <div key={index}>
                    <h2 className="bottomGrowth-h1">
                      {page.homePageSustainability.subSector20.title23}
                    </h2>
                    <p
                      className="bottomGrowth-p1"
                      dangerouslySetInnerHTML={{
                        __html:
                          page.homePageSustainability.subSector20.paragraph13,
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </center>
    </div>
  );
};

export default Growth;
